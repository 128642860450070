const WellnessProgramPaymentResources = {
    labelForProgramType: 'For Program Type',
    labelProgramType: 'Program Type',
    labelForPaymentType: 'Payment Type',
    labelForPartner: 'For Partner',
    labelPartner: 'Partner',
    labelForUsagePeriod: 'For Usage Period',
    labelVisitMonth: 'Visit Month',
    labelShowAllCorrectedPayments: 'Show All Corrected Payments',
    titlePartnerPayments: 'Partner Payments',
    titleWellnessProgramPayments: 'Wellness Program Payments',
    titlePartnerPaymentWithdrawalViaACHFiles: 'Partner Payment Withdrawal via US Bank',
    titleFacilityPaymentDepositViaACHFiles: 'Facility Payment Deposit via US Bank',
    titleConfirmACHUpload: 'Confirm your action',
    titleFacilityPayments: 'Facility Payments',
    buttonSendToUSBank: 'Send To US Bank',
    buttonDownloadForPreview: 'Download For Preview',
    buttonGenerate: 'Generate',
    buttonUndo: 'Undo',
    accessorStatus: 'status',
    accessorTotalPayments: 'totalPayment',
    accessorId: 'id',
    accessorAmountToBeSent: 'amountToBeSent',
    buttonGenerateWithFees: 'Generate with fees',
    formPartnerPaymentsGeneration: 'partnerPaymentsGenerationForm',
    formFacilityPaymentsGeneration: 'facilityPaymentsGenerationForm',
    fieldProgramPaymentType: 'programPaymentType',
    fieldProgramType: 'programType',
    labelPaymentType: 'Payment Type',
    fieldPaymentType: 'paymentType',
    buttonCancel: 'Cancel',
    fieldPartner: 'partner',
    fieldPeriod: 'period',
    formFacilityPaymentDepositViaACH: 'facilityPaymentDepositViaACHForm',
    formfacilityPaymentDepositConfirmation: 'facilityPaymentDepositConfirmationForm',
    formPaymentWithdrawalConfirmation: 'paymentWithdrawalConfirmationForm',
    formPaymentWithdrawalViaACHForm: 'paymentWithdrawalViaACHForm'
};
export default WellnessProgramPaymentResources;
