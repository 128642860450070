const uploadMemberRecipientsResources = {
    uploadSectionTitle: 'Invite Recipients',
    uploadSectionDescription: launchDate => `Please upload recipient's emails (25KB max file size). Either click the Upload button or Drag and Drop a file here. \n Available invitations will be automatically sent after program launch (${launchDate}).`,
    resultsPopupTitle: 'Recipients Upload Results',
    buttonViewDetails: 'View Details',
    validateUniqueEmailError: 'Email Address must be unique. Row(s) {0} have the same email address.',
    validateNumberOfMembersError: 'The total number of recipients exceeds the Number of Memberships.',
    validateExistingMembersError: 'Email Address must be unique. Recipient with {0} has already been uploaded.',
    resultsProcessed: 'PROCESSED',
    resultsUploaded: 'UPLOADED',
    resultsFailed: 'FAILED',
    extensionAccepted: ['xlsx'],
    fileSizeError: 'File size exceeds the limit of 25KB',
    memberRecipientsFileUploaderName: 'MemberRecipientsFileUploader'
};

export default uploadMemberRecipientsResources;