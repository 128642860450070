const homeResources = {
    dashboardTitle: 'Dashboard',
    groupMembershipTitle: 'Group Membership',
    labelPartner: 'Partner',
    labelMonthlyDues: 'Monthly Dues',
    labelRequiredVisit: 'Required Visits',
    labelReimbursementAmount: 'Reimbursement Amount',
    labelBillingDetails: 'Billing Details',
    labelMemberAgreement: 'Member Agreement',
    viewText: 'View',
    actiueClubTitle: 'Active Club',
    enrollInFacilityTitle: 'Enroll in Facility',
    noFacilitiesEnrolledText: 'No facilities enrolled. Please find facility below',
    findFacilityButtonText: 'Find Facility',
};
export default homeResources;