import * as types from './homeTypes';
import initialState from './homeInitials';
import { fromJS } from 'immutable';

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_MEMBER_INSURANCE_INFO_SUCCESS:
            return state.set('insuranceInfo', fromJS(action.insuranceInfo));
        default:
            return state;
    }
}