import { createSelector } from 'reselect';
import { SEND_PAYMENT_WITHDRAWAL, DOWNLOAD_PAYMENT_WITHDRAWAL, CHECK_PAYMENT_WITHDRAWAL, GET_PARTNERS } from './partnerPaymentWithdrawalSectionTypes';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';
import paymentTypesEnum from '../../../enums/paymentTypes';
import { GET_CLOSED_USAGE_PERIODS, GET_PERIODS_UNTIL_CURRENT } from '../../Shared/Periods/periodsTypes';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import { formValueSelector } from 'redux-form';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === SEND_PAYMENT_WITHDRAWAL
            || i === GET_PARTNERS
            || i === DOWNLOAD_PAYMENT_WITHDRAWAL
            || i === CHECK_PAYMENT_WITHDRAWAL
            || i === GET_CLOSED_USAGE_PERIODS
            || i === GET_PERIODS_UNTIL_CURRENT
    ).size;

    return size > 0;
};
const getPaymentWithdrawal = state => state.partnerPaymentWithdrawalSectionReducer.get('paymentWithdrawal');
const getPartners = state => state.partnerPaymentWithdrawalSectionReducer.get('partners');
const getPopupMessage = state => state.partnerPaymentWithdrawalSectionReducer.get('popupData').message;
const getPopupSubmitText = state => state.partnerPaymentWithdrawalSectionReducer.get('popupData').submitText;
const getIsAchUploadAvailable = state => state.wellnessProgramPaymentsReducer.getIn(['achUploadAvailability', 'isAchUploadAvailable']);
const getAchUploadErrorMessage = state => state.wellnessProgramPaymentsReducer.getIn(['achUploadAvailability', 'errorMessage']);
const formSelector = formValueSelector(wellnessProgramPaymentResources.formPaymentWithdrawalViaACHForm, state => state.form.toJS());
const getProgramPaymentTypeId = (state) => {
    const programPaymentType = formSelector(state, wellnessProgramPaymentResources.fieldProgramPaymentType);
    if (programPaymentType) {
        return programPaymentType.id;
    } else {
        return programPaymentTypesEnum.partnerPaid;
    }
};
const getPaymentTypeId = (state) => {
    const paymentType = formSelector(state, wellnessProgramPaymentResources.fieldPaymentType);
    if (paymentType) {
        return paymentType.id;
    } else {
        return paymentTypesEnum.gmPartnerFees;
    }
};

const getProgramTypeId = (state) => {
    const programType = formSelector(state, wellnessProgramPaymentResources.fieldProgramType);
    if (programType) {
        return programType.id;
    }
};

const getSelf = state => state;

const getPaymentTypes = state => state.partnerPaymentWithdrawalSectionReducer.get('paymentTypes');
const getPeriods = createSelector([getProgramPaymentTypeId, getPaymentTypeId, getSelf], (selectedProgramPaymentTypeID, selectedPaymentTypeID, state) => {
    if (selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerPaid
        || (selectedProgramPaymentTypeID === programPaymentTypesEnum.memberPaid && selectedPaymentTypeID === paymentTypesEnum.gmPartnerReimbursements)
        || (selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerMemberPaid && selectedPaymentTypeID === paymentTypesEnum.gmPartnerDues)) {
        return state.periodsReducer.get('closedUsagePeriods');
    }
    else {
        return state.periodsReducer.get('periodsUntilCurrent');
    }
});

export default createSelector(
    [getIsLoading, getPaymentWithdrawal, getPartners, getPopupMessage, getPopupSubmitText, getIsAchUploadAvailable, getAchUploadErrorMessage, getProgramPaymentTypeId, getPeriods, getProgramTypeId, getPaymentTypeId, getPaymentTypes],
    (isLoading, paymentWithdrawal, partners, popupMessage, popupSubmitText, isAchUploadAvailable, achUploadErrorMessage, programPaymentTypeId, periods, programTypeId, paymentTypeId, paymentTypes) => ({
        isLoading,
        paymentWithdrawal,
        partners,
        popupMessage,
        popupSubmitText,
        isAchUploadAvailable,
        achUploadErrorMessage,
        programPaymentTypeId,
        periods,
        programTypeId,
        paymentTypeId,
        paymentTypes
    })
);