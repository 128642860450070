import * as types from './memberAreaTypes';
import MemberAreaService from './memberAreaService';
import * as loadingActions from '../Loading/loadingActions';
import * as legalContractService from '../MemberProfile/FacilityInfo/LegalContract/legalContractService';


const setMemberHasActiveFacilityEnrollments = memberHasActiveFacilityEnrollments => ({
    type: types.SET_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS,
    payload: { memberHasActiveFacilityEnrollments }
});

const setMemberInvalidations = memberInvalidations => ({ type: types.SET_MEMBER_INVALIDATIONS, payload: { memberInvalidations } });

export const checkIfMemberHasActiveFacilityEnrollments = membersGuids => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(types.CHECK_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS));

    return MemberAreaService.getMemberHasActiveFacilityEnrollments(membersGuids)
        .then((memberHasActiveFacilityEnrollments) => {
            dispatch(setMemberHasActiveFacilityEnrollments(memberHasActiveFacilityEnrollments));
        })
        .finally(() => dispatch(loadingActions.unsetLoading(types.CHECK_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS)));
};

export const getMemberInvalidations = membersGuids => (dispatch) => {
    dispatch(loadingActions.setLoading(types.GET_MEMBER_INVALIDATIONS));

    return MemberAreaService.getMemberInvalidations(membersGuids)
        .then((membersInvalidations) => {
            dispatch(setMemberInvalidations(membersInvalidations));
        })
        .finally(() => dispatch(loadingActions.unsetLoading(types.GET_MEMBER_INVALIDATIONS)));
};

export const resignMemberLegalContract = memberLegalContract => (dispatch) => {
    dispatch(loadingActions.setLoading(types.RESIGN_MEMBER_LEGAL_CONTRACT));

    return legalContractService.createMemberLegalContract(memberLegalContract)
        .finally(() => {
            dispatch(loadingActions.unsetLoading(types.RESIGN_MEMBER_LEGAL_CONTRACT));
        });
};
