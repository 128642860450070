import * as loadingActions from '../../../Loading/loadingActions';
import * as types from './homeTypes';

const getMemberInsuranceInfoSuccess = (insuranceInfo) => {
    return { type: types.GET_MEMBER_INSURANCE_INFO_SUCCESS, insuranceInfo };
};

export function getMemberInsuranceInfo(memberGuids) {
    return function(dispatch, getState, { InsuranceInfoService }) {
        dispatch(loadingActions.setLoading(types.GET_MEMBER_INSURANCE_INFO));
        return InsuranceInfoService.getInsuranceInfo(memberGuids)
            .then((insuranceInfo) => {
                dispatch(getMemberInsuranceInfoSuccess(insuranceInfo[0]));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_MEMBER_INSURANCE_INFO));
            });
    };
}