import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import routes from '../../../routing/routes';
import { connect } from 'react-redux';
import selectors from './backToPartnersTopbarContentSelectors';

function BackToPartnersTopbarContent({ partnerName }) {
    return (<div className="topbar__content-information">
        You are currently controlling
        &nbsp;<span style={{ fontWeight: 'bold' }}>{partnerName ? partnerName.toUpperCase() : ''}</span>&nbsp;
        partner, to go back to the administration
        site,&nbsp;
        <NavLink
            className="link link_color_blue link_bold"
            to={routes.partnersList.path}
        >
            click here
        </NavLink>
    </div>);
}

BackToPartnersTopbarContent.propTypes = {
    partnerName: PropTypes.string
};

export default connect(selectors)(BackToPartnersTopbarContent);
