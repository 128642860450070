import { createSelector } from 'reselect';
import allRoutes from '../../routing/routes';
import { CHECK_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS, GET_MEMBER_INVALIDATIONS, RESIGN_MEMBER_LEGAL_CONTRACT } from './memberAreaTypes';
import { GET_SIGNATURE_MEMBER_INFO } from '../MemberProfile/FacilityInfo/LegalContract/legalContractTypes';
import invalidationTypes from '../../enums/invalidationTypes';

const getMemberHasActiveFacilityEnrollments = (state) => {
    return state.memberAreaReducer.get('memberHasActiveFacilityEnrollments');
};

export const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === CHECK_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS || i === GET_MEMBER_INVALIDATIONS || i === GET_SIGNATURE_MEMBER_INFO || i === RESIGN_MEMBER_LEGAL_CONTRACT
    ).size;

    return size > 0;
};

const checkShouldRedirect = createSelector(
    [getMemberHasActiveFacilityEnrollments],
    (memberHasActiveFacilityEnrollments) => {
        return memberHasActiveFacilityEnrollments !== undefined;
    }
);

const getRoutes = (state, props) => {
    return props.currentRoute.routes;
};

const selectFilteredRoutes = createSelector(
    [getMemberHasActiveFacilityEnrollments, getRoutes],
    (memberHasActiveFacilityEnrollments, routes) => {
        return routes.filter(
            (route) => {
                if (route.path === allRoutes.account.path) {
                    return memberHasActiveFacilityEnrollments;
                }

                return true;
            }
        );
    }
);

const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');

const getFirstMembersGuid = createSelector(
    [getSelectedMemberGroup],
    (selectedMemberGroup) => {
        return selectedMemberGroup.membersIds[0];
    });

const getMemberLegalContract = state => state.legalContractReducer.get('membersAFFacilityWithinProgramEnrollment');

const getProgramPaymentType = createSelector([getMemberLegalContract], (memberLegalContract) => { return memberLegalContract ? memberLegalContract.get('programPaymentType') : undefined; });

const getFacility = state => state.facilityReducer.get('facility');

const getFacilityAFNumber = createSelector(
    [getFacility],
    (facility) => {
        return facility.get('afNumber');
    }
);

const getIsAgreementResigningRequired = createSelector([getMemberHasActiveFacilityEnrollments, getMemberLegalContract], (hasActiveFacilityEnrollments, memberLegalContract) => {
    if (!hasActiveFacilityEnrollments) {
        return false;
    }
    if (!memberLegalContract) {
        return false;
    }
    return !memberLegalContract.get('isMemberAgreed');
});

const getFacilityId = createSelector([getFacility], facility => facility.get('id'));

const getIsShowTopbar = (state) => {
    return !!(state.memberAreaReducer.get('memberInvalidations')) && state.memberAreaReducer.get('memberInvalidations').filter(
        i => i.get('memberInvalidationTypeID') === invalidationTypes.Suspended).size > 0;
};

export default createSelector(
    [
        getIsLoading,
        checkShouldRedirect,
        selectFilteredRoutes,
        getSelectedMemberGroup,
        getFirstMembersGuid,
        getProgramPaymentType,
        getIsAgreementResigningRequired,
        getFacilityAFNumber,
        getFacilityId,
        getMemberHasActiveFacilityEnrollments,
        getIsShowTopbar
    ],
    (
        isLoading,
        shouldRedirect,
        filteredRoutes,
        selectedMemberGroup,
        firstMembersGuid,
        programPaymentType,
        isAgreementResigningRequired,
        facilityAFNumber,
        facilityId,
        hasActiveFacilityEnrollments,
        isShowTopbar
    ) => ({
        isLoading,
        shouldRedirect,
        filteredRoutes,
        selectedMemberGroup,
        firstMembersGuid,
        programPaymentType,
        isAgreementResigningRequired,
        facilityAFNumber,
        facilityId,
        hasActiveFacilityEnrollments,
        isShowTopbar
    })
);
