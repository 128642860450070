import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../components/Spinner/Spinner';
import { connect } from 'react-redux';
import selectors from '../wellnessProgramPaymentsSelectors';
import CardInfo from '../../../components/CardInfo/CardInfo';
import PartnerPaymentsSection from '../PartnerPaymentsSection/PartnerPaymentsSection';
import PartnerPaymentWithdrawalSection from '../PartnerPaymentWithdrawalSection/PartnerPaymentWithdrawalSection';
import PartnerPaymentsGenerationSection from '../PartnerPaymentsGenerationSection/PartnerPaymentsGenerationSection';

function PartnerPaymentsTab({
    isLoading,
    programPaymentTypes,
    programTypes,
    defaultProgramType,
    defaultProgramPaymentType,
    defaultPaymentType
}) {
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <div>
                <CardInfo>
                    <div className="grid__row">
                        <div className="col-lg-6">
                            <PartnerPaymentsGenerationSection
                                programPaymentTypes={programPaymentTypes}
                                programTypes={programTypes}
                                defaultProgramType={defaultProgramType}
                                defaultProgramPaymentType={defaultProgramPaymentType}
                                defaultPaymentType={defaultPaymentType}
                            />
                        </div>
                        <div className="col-lg-6">
                            <PartnerPaymentWithdrawalSection
                                programPaymentTypes={programPaymentTypes}
                                programTypes={programTypes}
                                defaultProgramType={defaultProgramType}
                                defaultProgramPaymentType={defaultProgramPaymentType}
                                defaultPaymentType={defaultPaymentType}
                            />
                        </div>
                    </div>
                    <PartnerPaymentsSection
                        programTypes={programTypes}
                        programPaymentTypes={programPaymentTypes}
                        defaultPaymentType={defaultPaymentType}
                    />
                </CardInfo>
            </div>
        );
}

PartnerPaymentsTab.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    programTypes: PropTypes.object.isRequired,
    defaultProgramType: PropTypes.object,
    defaultProgramPaymentType: PropTypes.object,
    defaultPartnerBillingType: PropTypes.object,
    defaultPaymentType: PropTypes.object,
    setGlobalErrorMessage: PropTypes.func
};

export default connect(selectors)(PartnerPaymentsTab);
