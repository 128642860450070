import * as types from './clubLocatorSectionTypes';
import clubLocatorSectionService from './clubLocatorSectionService';
import * as loadingActions from '../../features/Loading/loadingActions';
import clubLocatorResources from './clubLocatorSectionResources';

const setFacilitiesForMap = facilities => ({
    type: types.SET_FACILITIES_FOR_MAP,
    facilities
});

const setPlansForMap = plans => ({
    type: types.SET_PLANS_FOR_MAP,
    plans
});

export const getFacilitiesForMap = plansFilter => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(types.GET_FACILITIES_FOR_MAP));
    const hasFilter = plansFilter && plansFilter.length > 0;
    clubLocatorSectionService.getFacilitiesForMap(
        hasFilter ?
            plansFilter.filter(filter => (filter.checked && filter.name !== clubLocatorResources.groupMembershipsOptionText))
                .map(filter => filter.id)
            : null,
        hasFilter ?
            plansFilter
                .filter(filter => filter.name !== clubLocatorResources.groupMembershipsOptionText
                    && filter.programNames && filter.programNames.some(program => program.checked))
                .map(filter =>
                    filter.programNames.filter(program => program.checked).map(program => program.id))
                .flat(1)
            : null,
        hasFilter && plansFilter.some(filter => filter.checked && filter.name === clubLocatorResources.groupMembershipsOptionText))
        .then((facilities) => {
            dispatch(setFacilitiesForMap(facilities));
        })
        .finally(() => dispatch(loadingActions.unsetLoading(types.GET_FACILITIES_FOR_MAP)));
};

export const getPlansForMap = () => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(types.GET_PLANS_FOR_MAP));
    clubLocatorSectionService.getPlansForMap()
        .then((plans) => {
            dispatch(setPlansForMap([
                ...[
                    {
                        id: 0,
                        name: clubLocatorResources.groupMembershipsOptionText,
                        checked: false,
                        hasRestrictedOptions: false,
                        programNames: []
                    }],
                ...plans]));
        })
        .finally(() => dispatch(loadingActions.unsetLoading(types.GET_PLANS_FOR_MAP)));
};