import { createSelector } from 'reselect';
import { GET_MEMBER_INSURANCE_INFO } from './homeTypes';

const currentUser = state => state.app.get('me');
const getHasGroupMembership = createSelector([currentUser], me => me.hasGroupMembership);
const getSelectedMemberGroup = state =>state.app.get('selectedMemberGroup');
const getInsuranceInfo = state => state.homeReducer.get('insuranceInfo');

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_MEMBER_INSURANCE_INFO)
        .size;

    return size > 0;
};

export default createSelector(
    [
        getIsLoading,
        getHasGroupMembership,
        getInsuranceInfo,
        getSelectedMemberGroup
    ],
    (isLoading, hasGroupMembership, insuranceInfo, selectedMemberGroup) => ({
        isLoading,
        hasGroupMembership,
        insuranceInfo,
        selectedMemberGroup
    })
);