import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureInterception } from './http/interception';
import configureStore, { history } from './store/configureStore.js';
import TagManager from 'react-gtm-module';
import AppWithFlagsSupport from './features/App/AppWithFlagsSupport';
import * as serviceWorker from './serviceWorker';
import './fonts/stylesheet.css';
import './styles/index.scss';
import { ConnectedRouter } from 'connected-react-router';
import ScrollTo from './routing/ScrollTo';
import { StylesProvider } from '@material-ui/core/styles';
import ReactGA from 'react-ga4';

const store = configureStore({});
configureInterception({ dispatch: store.dispatch });

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_ACCOUNT_CONVERSION_ID);
    ReactGA.event('conversion', {
        send_to: `${process.env.REACT_APP_GA_ACCOUNT_CONVERSION_ID}/${process.env.REACT_APP_GA_CONVERSION_PAGE_ID}`
    });
    TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_CONTAINER_ID
    });
}

const rootElement = document.getElementById('root');
const APP = (<StylesProvider injectFirst>
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ScrollTo />
            <AppWithFlagsSupport />
        </ConnectedRouter>
    </Provider>
</StylesProvider>);
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(APP, rootElement);
} else {
    ReactDOM.render(APP, rootElement);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
