import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_info-box.scss';

export const InfoBoxRow = (props) => {
    const { className, label, labelComponent, content, children, contentposition, bold = false, bottomborder = false } = props;
    return (
        <div
            className={classNames(['info-box__row', { 'info-box__row--bold': bold }, { 'info-box__row--bottom-border': bottomborder }, { [className]: className }])}
        >
            {label && (
                <div className="info-box__cell info-box__label" title={label}>
                    {labelComponent ? labelComponent : label}
                </div>
            )}
            <div
                className={classNames(['info-box__cell', `info-box__cell--${contentposition}`])}
                title={typeof content === 'string' ? content : ''}
            >
                {content == null ? children : content}
            </div>
        </div >
    );
};

InfoBoxRow.defaultProps = {
    contentposition: 'left'
};

InfoBoxRow.propTypes = {
    children: PropTypes.node,
    content: PropTypes.node,
    label: PropTypes.string,
    labelComponent: PropTypes.node,
    className: PropTypes.string,
    contentposition: PropTypes.string,
    bold: PropTypes.bool,
    bottomborder: PropTypes.bool
};

export default InfoBoxRow;
