import * as types from './types';
import { initialState } from './initials';
import { fromJS } from 'immutable';

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case types.SET_LOADING:
            return state.set('isLoading', true);
        case types.UNSET_LOADING:
            return state.set('isLoading', false);
        case types.LOAD_ME:
            return state.set('me', payload.me);
        case types.CHANGE_SELECTED_MEMBER_GROUP:
            return state.set(
                'selectedMemberGroup',
                payload.selectedMemberGroup
            );
        case types.SET_GLOBAL_ERROR_MESSAGE:
            return state.set('globalErrorMessage', fromJS(action.globalErrorMessage));
        default:
            return state;
    }
}
