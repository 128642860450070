import React from 'react';
import PropTypes from 'prop-types';
import './_topbar.scss';

function Topbar({ topbarContent }) {
    if (!topbarContent) {
        return false;
    }
    return <div className="topbar">
        <div className="topbar__content">
            {React.createElement(topbarContent)}
        </div>
    </div>;
};

Topbar.propTypes = {
    topbarContent: PropTypes.object
};

export default Topbar;