import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import selectors from './messagesTopbarContentSelectors';

function MessagesTopbarContent({ globalInformationMessage, globalWarningMessage, globalErrorMessage }) {
    return (<>
        {globalInformationMessage && <div className="topbar__content-information">{globalInformationMessage}</div>}
        {globalWarningMessage && <div className="topbar__content-warning">{globalWarningMessage}</div>}
        {globalErrorMessage && <div className="topbar__content-error" dangerouslySetInnerHTML={{ __html: globalErrorMessage }} />}
    </>);
}

MessagesTopbarContent.propTypes = {
    globalErrorMessage: PropTypes.string,
    globalInformationMessage: PropTypes.string,
    globalWarningMessage: PropTypes.string,
};

export default connect(selectors)(MessagesTopbarContent);
