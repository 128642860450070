import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../../components/Spinner/Spinner';
import selectors from './legalContractSelectors';
import { getMemberLegalContractInfo } from './legalContractActions';
import LegalContractForm from './LegalContractForm/legalContractForm';
import legalContractResources from './legalContractResources';
import { format } from 'react-string-format';
import './_legalContract.scss';
import IconCaption from '../../../../components/IconCaption/IconCaption';
import MultiStepForms from '../../../../components/FormStep/MultiStepForms';
import KeyfobForm from './LegalContractSteppedFormForms/KeyfobForm';
import AgreementForm from './LegalContractSteppedFormForms/AgreementForm';
import BankingInfoStepForm from '../../../Shared/BankingInfo/BankingInfoStepForm';
import GMSPPaymentDetailsForm from './LegalContractSteppedFormForms/GMSPPaymentDetailsForm';
import GMRPaymentDetailsForm from './LegalContractSteppedFormForms/GMRPaymentDetailsForm';
import programPaymentTypes from '../../../../enums/programPaymentTypes';
import bankOperationTypes from '../../../../enums/bankOperationTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { change } from 'redux-form';
import BankingInfoResources from '../../../Shared/BankingInfo/bankingInfoResources';
import MaterialIcon from '../../../../components/MaterialIcon/MaterialIcon';
import routes from '../../../../routing/routes';

function LegalContract({
    isLoading,
    facilityDisplayName,
    facilityFullAddress,
    memberIdGuid,
    readOnly,
    membersAFFacilityWithinProgramEnrollment,
    getMemberLegalContractInfo,
    handleSubmit,
    programPaymentType,
    facilityAFNumber,
    documentAgreementVersion,
    formValues,
    history,
    change,
    partnerID,
    agreementDate,
    isDepositInfoVisible }) {
    useEffect(() => {
        if (!membersAFFacilityWithinProgramEnrollment) {
            getMemberLegalContractInfo(memberIdGuid);
        }
    }, [memberIdGuid]);
    const { gmrAddProgram, gmAccessPassFeature } = useFlags();
    const forms = gmrAddProgram && gmAccessPassFeature ? [] : [{ title: legalContractResources.legalContractTitleKeyfobStep, formComponent: KeyfobForm }];
    if (gmrAddProgram) {
        if (programPaymentType === programPaymentTypes.partnerMemberPaid) {
            forms.push({
                title: legalContractResources.legalContractTitleWithdrawalDetailsStep, formComponent: BankingInfoStepForm,
                formParams: {
                    bankAccountModelName: legalContractResources.withdrawalFromBankAccountModelName,
                    operationTypeID: bankOperationTypes.Debit,
                    description: BankingInfoResources.descriptionForWithdrawalBankingInfo
                }
            });
            forms.push({ title: legalContractResources.legalContractTitlePaymentDetailsStep, formComponent: GMSPPaymentDetailsForm, formParams: { partnerID, agreementDate } });
        };
        if (programPaymentType === programPaymentTypes.memberPaid) {
            forms.push({
                title: legalContractResources.legalContractTitleWithdrawalDetailsStep, formComponent: BankingInfoStepForm,
                formParams: {
                    bankAccountModelName: legalContractResources.withdrawalFromBankAccountModelName,
                    operationTypeID: bankOperationTypes.Debit,
                    description: BankingInfoResources.descriptionForWithdrawalBankingInfo
                }
            });
            if (isDepositInfoVisible) {
                const setWithdrawalFromBankAccountInfo = () => {
                    if (formValues) {
                        change(legalContractResources.formNameLegalContract, legalContractResources.depositToBankAccountModelName, formValues.get(legalContractResources.withdrawalFromBankAccountModelName));
                    }
                };
                forms.push({
                    title: legalContractResources.legalContractTitleDepositDetailsStep, formComponent: BankingInfoStepForm,
                    formParams: {
                        bankAccountModelName: legalContractResources.depositToBankAccountModelName,
                        operationTypeID: bankOperationTypes.Credit,
                        setBankAccountInfo: setWithdrawalFromBankAccountInfo,
                        description: BankingInfoResources.descriptionForWithdrawalBankingInfo
                    }
                });
            }
            forms.push({ title: legalContractResources.legalContractTitlePaymentDetailsStep, formComponent: GMRPaymentDetailsForm, formParams: { partnerID, agreementDate } });
        }
    };
    forms.push({ title: legalContractResources.legalContractTitleAgreementStep, formComponent: AgreementForm, formParams: { programPaymentType, facilityAFNumber } });
    return isLoading || (readOnly && !documentAgreementVersion)
        ? <Spinner />
        : <div className="legal-contract">
            <div className="legal-contract__title-container">
                {
                    history
                        ? <div className="legal-contract__title-container_back-button" onClick={() => {
                            history.push(routes.facilityLocator.path);
                        }}>
                            <MaterialIcon className="prev-arrow" icon="arrow_back" />
                        </div>
                        : <></>
                }
                <h3 className="legal-contract__title-container_title">{format(legalContractResources.legalCotractTitleFormat, handleSubmit ? legalContractResources.legalCotractTitleEnroll : legalContractResources.legalCotractTitleEnrolled, facilityDisplayName)}</h3>
            </div>
            {
                facilityFullAddress ?
                    <div className="legal-contract__subtitle">
                        <IconCaption icon="place" className="legal-contract__box-icon" children={facilityFullAddress} />
                    </div>
                    : <></>
            }
            {
                readOnly ?
                    <LegalContractForm initialValues={membersAFFacilityWithinProgramEnrollment} onSubmit={handleSubmit} readOnly={readOnly} documentAgreementVersion={documentAgreementVersion}
                        programPaymentType={programPaymentType} facilityAFNumber={facilityAFNumber}
                    />
                    : <MultiStepForms forms={forms} onSubmit={handleSubmit} formName={legalContractResources.formNameLegalContract} />
            }
        </div>;
}

LegalContract.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    getMemberLegalContractInfo: PropTypes.func.isRequired,
    membersAFFacilityWithinProgramEnrollment: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func,
    memberIdGuid: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    facilityDisplayName: PropTypes.string,
    facilityFullAddress: PropTypes.string,
    programPaymentType: PropTypes.number,
    facilityAFNumber: PropTypes.string.isRequired,
    documentAgreementVersion: PropTypes.number,
    formValues: PropTypes.object,
    history: PropTypes.object,
    change: PropTypes.func,
    partnerID: PropTypes.number,
    agreementDate: PropTypes.string,
    isDepositInfoVisible: PropTypes.bool
};

const mapDispatchToProps = {
    getMemberLegalContractInfo,
    change
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(LegalContract);