import React from 'react';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';
import Button from './Button';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import styles from './_dragdropfileuploader.module.scss';

function DragDropFileUploader({
    name,
    title,
    subtitle,
    buttonName,
    handleChange,
    fileTypes
}) {
    return (
        <FileUploader
            handleChange={handleChange}
            name={name}
            classes={styles.upload_dropzone}
            types={fileTypes}
        >
            <div className={styles.upload_container}>
                <div>
                    {title && <h3 className={styles.upload_title}>{title}</h3>}
                    {subtitle && <label className={styles.upload_subtitle}>{subtitle}</label>}
                </div>
                <Button className="btn_new btn_new--white margin-auto"
                    size="small"
                    variant="contained"
                >
                    <MaterialIcon className="btn_new-icon btn_new-icon--left" icon="upload_file" />
                    {buttonName}
                </Button>
            </div>
        </FileUploader>
    );
};

DragDropFileUploader.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    fileTypes: PropTypes.array,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonName: PropTypes.string,
};

DragDropFileUploader.defaultProps = {
    buttonName: 'upload',
};

export default DragDropFileUploader;