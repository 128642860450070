import { HTTPS_PREFIX, GOOGLE_MAPS_URL } from '../../constants/websites';
import { TEL_PREFIX }  from '../../constants/phones';


export const formatFacilityUrl = (url) => {
    if(!url.startsWith(HTTPS_PREFIX)) {
        return HTTPS_PREFIX.concat(url);
    }
    return url;
};

export const formatPhoneUrl = (phoneNumber) => {
    return TEL_PREFIX.concat(phoneNumber);
};

export const formatGoogleMapsUrl = function(facilityAddress) {
    return GOOGLE_MAPS_URL.concat(facilityAddress);
};