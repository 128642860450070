import * as types from './memberAreaTypes';
import { initialState } from './memberAreaInitials';
import { fromJS } from 'immutable';

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case types.SET_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS:
            return state.set(
                'memberHasActiveFacilityEnrollments',
                payload.memberHasActiveFacilityEnrollments
            );
        case types.SET_MEMBER_INVALIDATIONS:
            return state.set('memberInvalidations', fromJS(payload.memberInvalidations));
        default:
            return state;
    }
}
