const prefix = 'PARTNER_PAYMENT_WITHDRAWAL';
export const SET_POPUP_DATA = `${prefix}_SET_POPUP_DATA`;
export const SET_PROGRAM_TYPE = `${prefix}_SET_PROGRAM_TYPE`;
export const SET_PROGRAM_PAYMENT_TYPE = `${prefix}_SET_PROGRAM_PAYMENT_TYPE`;
export const SEND_PAYMENT_WITHDRAWAL = `${prefix}_SEND`;
export const CHECK_PAYMENT_WITHDRAWAL = `${prefix}_CHECK`;
export const DOWNLOAD_PAYMENT_WITHDRAWAL = `${prefix}_DOWNLOAD`;
export const GET_PARTNERS = `${prefix}_GET_PARTNERS`;
export const SET_PARTNERS = `${prefix}_SET_PARTNERS`;
export const SET_PAYMENT_TYPE = `${prefix}_SET_PAYMENT_TYPE`;
export const SET_PAYMENT_TYPES = `${prefix}_SET_PAYMENT_TYPES`;
export const GET_PAYMENT_TYPES = `${prefix}_GET_PAYMENT_TYPES`;
export const SET_PERIOD = `${prefix}_SET_PERIOD`;
export const SET_PARTNER = `${prefix}_SET_PARTNER`;