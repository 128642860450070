import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import routes from '../../routing/routes';
import './header.scss';

function GuestHeaderNavList({ isMobile }) {
    return (
        <ul className="header__nav-list">
            {isMobile && <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.home.path}
                    activeClassName="header__nav-item--active"
                >
                    home page
                </NavLink>
            </li>}
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.forFacilities.path}
                    activeClassName="header__nav-item--active"
                >
                    for facilities
                </NavLink>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.forEmployers.path}
                    activeClassName="header__nav-item--active"
                >
                    for employers
                </NavLink>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.forInsurers.path}
                    activeClassName="header__nav-item--active"
                >
                    for insurers
                </NavLink>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.forMembers.path}
                    activeClassName="header__nav-item--active"
                >
                    for members
                </NavLink>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.aboutUs.path}
                    activeClassName="header__nav-item--active"
                >
                    about us
                </NavLink>
            </li>
        </ul>
    );
}

GuestHeaderNavList.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};
export default GuestHeaderNavList;
