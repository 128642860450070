import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_card-info.scss';

const CardInfo = ({ children, title, subtitle, shadow, halfSpace, noPadding, noInnerMargin }) => {
    return (
        <div className = {classNames(['card-info', { 'card-info--shadow': shadow }, { 'card-info--half': halfSpace }, { 'card-info--no-padding': noPadding }])}>
            {title && <h3 className="card-info__title">{title}</h3>}
            {subtitle && <p className="card-info__subtitle heading-6">{subtitle}</p>}
            <div className = {classNames(['card-info__content grid__row', { 'card-info--no-inner-margin': noInnerMargin }])}>{children}</div>
        </div>
    );
};

CardInfo.defaultProps = {
    shadow: true,
    noPadding: false,
    noInnerMargin: false
};

CardInfo.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    shadow: PropTypes.bool,
    halfSpace: PropTypes.bool,
    noPadding: PropTypes.bool,
    noInnerMargin: PropTypes.bool
};

export default CardInfo;
