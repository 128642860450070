import { createSelector } from 'reselect';

const getInformationMessage = state => state.app.get('globalInformationMessage');
const getWarningMessage = state => state.app.get('globalWarningMessage');
const getErrorMessage = state => state.app.get('globalErrorMessage');

export default createSelector(
    [getInformationMessage, getWarningMessage, getErrorMessage],
    (globalInformationMessage, globalWarningMessage, globalErrorMessage) => ({
        globalInformationMessage, globalWarningMessage, globalErrorMessage
    })
);