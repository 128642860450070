import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import selectors from './memberAreaSelectors';
import { checkIfMemberHasActiveFacilityEnrollments, getMemberInvalidations, resignMemberLegalContract } from './memberAreaActions';
import { getMemberLegalContractInfo } from '../MemberProfile/FacilityInfo/LegalContract/legalContractActions';
import routes, { routeType } from '../../routing/routes';
import ConditionalRedirect from '../../routing/conditionalRedirect';
import CustomRoute from '../../layouts/CustomRoute';
import Spinner from '../../components/Spinner/Spinner';
import CustomPopup from '../../components/Popups/CustomPopup/CustomPopup';
import AgreementForm from '../MemberProfile/FacilityInfo/LegalContract/LegalContractSteppedFormForms/AgreementForm';
import '../MemberProfile/FacilityInfo/LegalContract/_legalContract.scss';
import './_memberArea.scss';
import { getMemberFacility } from '../Facilities/facilityActions';
import bankOperationTypes from '../../enums/bankOperationTypes';
import memberAreaResources from './memberAreaResources';
import { setGlobalErrorMessage } from '../App/actions';
import { renderToString } from 'react-dom/server';

function MemberArea({ isLoading, currentRoute, filteredRoutes, shouldRedirect, selectedMemberGroup, firstMembersGuid,
    getMemberInvalidations, checkIfMemberHasActiveFacilityEnrollments, getMemberLegalContractInfo, programPaymentType, isAgreementResigningRequired,
    getMemberFacility, facilityAFNumber, facilityId, resignMemberLegalContract, hasActiveFacilityEnrollments, setGlobalErrorMessage, isShowTopbar }) {

    useEffect(() => {
        if (selectedMemberGroup) {
            checkIfMemberHasActiveFacilityEnrollments(selectedMemberGroup.membersIds);
            getMemberInvalidations(selectedMemberGroup.membersIds);
        }
    }, []);

    useEffect(() => {
        getMemberLegalContractInfo(firstMembersGuid);
        if (hasActiveFacilityEnrollments) {
            getMemberFacility(firstMembersGuid);
        }
    }, [hasActiveFacilityEnrollments]);

    useEffect(() => {
        setGlobalErrorMessage(renderToString((isShowTopbar && <>{memberAreaResources.labelActionRequiredForMember} &nbsp;
            <a className="link link_color_white link_bold" href={routes.billing.path}>
                {memberAreaResources.linkText}
            </a></>)));
    }, [isShowTopbar]);

    const handleSubmit = (legalcontractmodel) => {
        const contract = legalcontractmodel.toJS();
        contract.facilityId = facilityId;
        contract.memberIds = selectedMemberGroup.membersIds;
        contract.operationTypeID = bankOperationTypes.Debit;
        return resignMemberLegalContract(contract).then(() => {
            getMemberLegalContractInfo(firstMembersGuid);
        });
    };

    return (isLoading
        ? <Spinner />
        : <>
            <Switch>
                {filteredRoutes.map((route, i) => (
                    <CustomRoute
                        key={i}
                        path={route.path}
                        currentRoute={route}
                        filteredRoutes={filteredRoutes}
                    />
                ))}

                <ConditionalRedirect
                    condition={shouldRedirect}
                    fromRoute={currentRoute}
                    toRoute={filteredRoutes[0]}
                />
            </Switch>
            {facilityAFNumber && <CustomPopup overlayClassName="resign-agreement-popup" showPopup={isAgreementResigningRequired} body={<div className="legal-contract"><AgreementForm form="formAgreementResign" formParams={{ programPaymentType, facilityAFNumber }} onFormSubmit={handleSubmit} /></div>} title={memberAreaResources.titleResignAgreementPopup} />}
        </>
    );
};

MemberArea.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentRoute: routeType.isRequired,
    shouldRedirect: PropTypes.bool,
    filteredRoutes: PropTypes.arrayOf(routeType),
    checkIfMemberHasActiveFacilityEnrollments: PropTypes.func.isRequired,
    getMemberInvalidations: PropTypes.func.isRequired,
    selectedMemberGroup: PropTypes.object.isRequired,
    firstMembersGuid: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    getMemberLegalContractInfo: PropTypes.func.isRequired,
    isAgreementResigningRequired: PropTypes.bool.isRequired,
    programPaymentType: PropTypes.number,
    getMemberFacility: PropTypes.func.isRequired,
    facilityAFNumber: PropTypes.string,
    facilityId: PropTypes.number,
    resignMemberLegalContract: PropTypes.func.isRequired,
    hasActiveFacilityEnrollments: PropTypes.bool,
    setGlobalErrorMessage: PropTypes.func.isRequired,
    isShowTopbar: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
    checkIfMemberHasActiveFacilityEnrollments,
    getMemberInvalidations,
    getMemberLegalContractInfo,
    getMemberFacility,
    resignMemberLegalContract,
    setGlobalErrorMessage
};

export default connect(selectors, mapDispatchToProps)(MemberArea);
